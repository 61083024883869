import React from 'react'
import styled from 'styled-components'
import {
  sectionGrid,
  colors,
  VW,
  typography
} from '../../styles'
import Button from '../Button'
import RichText from '../RichText'

const Transparency = ({
  transparencySectionTitle,
  transparencySectionText,
  transparencySectionButton
}) => {
  return (
    <StyledTransparency>
      <StyledTransparencyContainer>
        <TitleWrapper>
          <TitleContainer>
            <RichText json={transparencySectionTitle} />
          </TitleContainer>
        </TitleWrapper>
        <Text>
          <RichText json={transparencySectionText} />
        </Text>
        {transparencySectionButton && (
          <ButtonWrapper>
            <Button
              title={transparencySectionButton.title}
              link={transparencySectionButton.urlLink}
              color='red'
              type='small' />
          </ButtonWrapper>
        )}
      </StyledTransparencyContainer>
    </StyledTransparency>
  )
}

const StyledTransparency = styled.div`
  padding: 40px 20px 70px;
  color: ${colors.red};
  background-color: ${colors.pink};

  @media (min-width: ${VW.desktop}) {
    padding-top: 100px;
    padding-bottom: 175px;
  }
`

const StyledTransparencyContainer = styled.div`
  max-width: 600px;
  margin: auto;

  @media (min-width: ${VW.desktop}) {
    ${sectionGrid};
  }
`

const TitleWrapper = styled.div`
position: relative;
  margin-bottom: 40px;
  max-width: 200px;

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 1;
    grid-column-end: 5;
    max-width: initial;
    margin: 0;
  }
`

const TitleContainer = styled.div`
  ${typography.headlines.regular}
  position: relative;
  display: initial;

  p {
    margin: 0;

    &:first-child u {
      &::before {
        width: 100%;
        background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%22109%22%20height%3D%224%22%20viewBox%3D%220%200%20109%204%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M0.851562%202.47327C33.5611%201.90565%2066.2705%201.30401%2098.9815%200.826474C114.655%200.597664%20104.791%200.926998%20101.403%201.11709C74.5721%202.62246%2047.7676%203.05625%2020.9038%203.34511%22%20stroke%3D%22%23E32844%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%2F%3E%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E');
      }
    }

    &:nth-child(2) u {
      &::before {
        width: 150%;
        background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg%20width%3D%22198%22%20height%3D%2210%22%20viewBox%3D%220%200%20198%2010%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M1.46484%207.16802C39.1293%207.31206%2076.9599%209.24739%20114.61%208.18517C127.878%207.81082%20167.44%204.37586%20154.181%203.72912C117.381%201.93397%2080.3483%203.22352%2043.5067%201.74327C37.1385%201.48741%2030.9455%200.986815%2024.5685%201.50109C5.44962%203.04294%2062.9287%201.93701%2082.1096%201.93701C120.325%201.93701%20158.541%201.93701%20196.756%201.93701%22%20stroke%3D%22%23E32844%22%20strokeLinecap%3D%22round%22%20strokeLinejoin%3D%22round%22%2F%3E%0D%0A%20%20%20%20%20%20%20%20%20%20%20%20%20%20%3C%2Fsvg%3E');
      }
    }
  }

  u {
    position: relative;
    display: inline-block;
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      top: calc(100% - 7px);
      left: 50%;
      transform: translate(-50%);
      height: 10px;
      display: block;
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: 100% 100%;
    }
  }

  @media (min-width: ${VW.desktop}) {
    ${typography.headlines.articleAlternate}
  }

  .top-svg,
  .bottom-svg {
    position: absolute;
  }

  .top-svg {
    top: 12px;
    right: -5px;
    

    svg {
      width: 70px;
    }

    @media (min-width: ${VW.desktop}) {
      ${typography.headlines.articleAlternate}
      top: 18px;
      right: -10px;

      svg {
        width: initial;
      }
    }
  }

  .bottom-svg {
    top: 55px;
    right: -6px;

    svg {
      width: 131px;
    }

    @media (min-width: ${VW.desktop}) {
      ${typography.headlines.articleAlternate}
      top: 70px;
      right: -34px;

      svg {
        width: initial;
      }
    }
  }
`

const Text = styled.div`
  p { margin: 0; }
  ${typography.body.big}

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 8;
    grid-column-end: 13;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 40px;

  @media (min-width: ${VW.desktop}) {
    grid-column-start: 8;
    grid-column-end: initial;
    margin-top: 50px;
  }
`

export default Transparency