import React from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Hero from '../components/hero/Hero'
import FeaturedCollection from '../components/home/FeaturedCollection'
import PhotoStrip from '../components/PhotoStrip'
import HomeAboutUs from '../components/home/HomeAboutUs'
import Transparency from '../components/home/Transparency'
import BlogModulesSection from '../components/home/BlogModulesSection'

const Home = ({
  data: {
    contentfulHomePage: {
      seoTitle,
      seoDescription,
      seoImage,
      heroCarouselItems,
      featuredList: {
        intro: {
          type: featuredProducts_flavType,
          flavLeft: {
            json: featuredProducts_flavLeft
          },
          flavRight: {
            json: featuredProducts_flavRight
          }
        },
        featuredProducts
      },
      aboutUsSectionIntro: {
        type: aboutUs_flavType,
        flavLeft: {
          json: aboutUs_flavLeft
        },
        flavRight: {
          json: aboutUs_flavRight
        }
      },
      aboutUsSectionText: {
        json: aboutUsSectionText
      },
      featuredProductsFillerImage,
      imageTicker,
      aboutUsSectionBulletPoints,
      aboutUsSectionButton,
      transparencySectionTitle: {
        json: transparencySectionTitle
      },
      transparencySectionText: {
        json: transparencySectionText
      },
      transparencySectionButton,
      blogSectionTitle,
      blogSectionButton,
      blogSectionModules: {
        sections: blogModules
      }
    },
  },
}) => {
  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.fluid.src}
        slug='' />

      <Hero carouselItems={heroCarouselItems} />
      <FeaturedCollection
        products={featuredProducts}
        featuredProducts_flavType={featuredProducts_flavType}
        featuredProducts_flavLeft={featuredProducts_flavLeft}
        featuredProducts_flavRight={featuredProducts_flavRight}
        featuredProductsFillerImage={featuredProductsFillerImage} />
      <PhotoStrip images={imageTicker.map((i) => ({
        src: i.resize.src,
        width: i.resize.width,
        height: i.resize.height,
        alt: i.description || ''
      }))} />
      <HomeAboutUs
        aboutUs_flavType={aboutUs_flavType}
        aboutUs_flavLeft={aboutUs_flavLeft}
        aboutUs_flavRight={aboutUs_flavRight}
        aboutUsSectionText={aboutUsSectionText}
        aboutUsSectionBulletPoints={aboutUsSectionBulletPoints}
        aboutUsSectionButton={aboutUsSectionButton} />
      <BlogModulesSection
        blogSectionTitle={blogSectionTitle}
        blogSectionButton={blogSectionButton}
        sections={blogModules} />
      <Transparency
        transparencySectionTitle={transparencySectionTitle}
        transparencySectionText={transparencySectionText}
        transparencySectionButton={transparencySectionButton} />
    </>
  )
}

export const PageQuery = graphql`
  query Home($id: String!) {
    contentfulHomePage(id: { eq: $id }) {
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
        }
      }
      heroCarouselItems {
        centerButton {
          title
          urlLink
        }
        centerText {
          json
        }
        image {
          fluid(maxWidth: 1600, quality: 80) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
        bottomRightBigText
        topLeftBigText
      }
      imageTicker {
        description
        resize(height: 512) {
          src
          width
          height
        }
      }
      featuredList {
        intro {
          type
          flavLeft {
            json
          }
          flavRight {
            json
          }
        }
        featuredProducts {
          shopifyProduct {
            handle
            variants {
              edges {
                node {
                  price
                }
              }
            }
          }
          title
          homepageDescription {
            json
          }
          slug
          thumbnailHover {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          thumbnail {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      featuredProductsFillerImage {
        title
        fluid(maxWidth: 1600, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      aboutUsSectionIntro {
        type
        flavLeft {
          json
        }
        flavRight {
          json
        }
      }
      aboutUsSectionText {
        json
      }
      aboutUsSectionBulletPoints
      aboutUsSectionButton {
        title
        urlLink
      }
      transparencySectionTitle {
        json
      }
      transparencySectionText {
        json
      }
      transparencySectionButton {
        title
        urlLink
      }
      blogSectionTitle {
        json
      }
      blogSectionButton {
        title
        urlLink
      }
      blogSectionModules {
        sections {
          ... on Node {
            ... on ContentfulBlogModuleVideo {
              id
              text {
                json
              }
              video {
                file {
                  contentType
                  url
                }
              }
              videoModal {
                videoButton {
                  title
                  urlLink
                }
                embeddedVideo {
                  json
                }
                videoAsset {
                  file {
                    url
                  }
                }
                videoDescription {
                  json
                }
                videoTitle
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulBlogModuleImageLink {
              id
              button {
                title
                urlLink
              }
              image {
                fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              text {
                json
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulBlogModuleImageAsset {
              image {
                fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulBlogModuleContent {
              id
              headline {
                json
              }
              text {
                json
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulBlogModuleCarousel {
              id
              images {
                fluid(maxWidth: 1600, quality: 80) {
                ...GatsbyContentfulFluid_withWebp_noBase64
                }
                description
              }
              text {
                json
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
            ... on ContentfulBlogModuleSpotifyPlaylist {
              id
              spotifyEmbeded {
                json
              }
              sys {
                contentType {
                  sys {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Home

