import React from 'react'
import styled from 'styled-components'
import {
  sectionGrid,
  VW,
  typography
} from '../../styles'
import Button from '../Button'
import FlavText from '../FlavText'
import RichText from '../RichText'

const icons = [
    <svg width="22" height="25" viewBox="0 0 22 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.78723 10.833C8.6008 9.00244 9.67047 7.3013 10.4394 5.44891C10.5488 5.18529 10.5957 4.89472 10.7111 4.63672C10.7326 4.58865 10.7528 4.43841 10.7672 4.48905C10.9309 5.06193 11.0973 5.63667 11.3195 6.19021C11.651 7.01599 12.1245 7.7808 12.5186 8.57657C12.7207 8.98473 12.8837 9.43982 13.13 9.82291C13.2904 10.0724 13.375 10.0446 13.7 10.0326C14.7712 9.99293 15.8616 9.81764 16.9192 9.65162C17.5513 9.55239 18.2159 9.47942 18.833 9.30311C18.8941 9.28565 18.9797 9.28279 18.9157 9.36809C18.7031 9.65149 18.4019 9.90012 18.1478 10.1448C17.1152 11.1392 16.1829 12.2298 15.2741 13.3404C14.9708 13.7112 14.5003 14.135 14.2847 14.5661C14.2624 14.6108 14.8264 15.1428 14.8548 15.1775C15.5401 16.0162 16.1554 16.9103 16.8483 17.744C17.5671 18.6089 18.2862 19.4725 18.9984 20.343C19.0404 20.3943 19.2165 20.5615 19.0752 20.5615C18.7269 20.5615 18.3872 20.3603 18.0887 20.2042C17.32 19.8019 16.5778 19.3367 15.8382 18.884C15.1871 18.4854 14.5154 18.1049 13.9097 17.6377C13.3284 17.1892 12.8039 16.6591 12.2292 16.1993C11.9866 16.0053 11.7429 15.7727 11.5085 15.5644C11.4547 15.5165 10.1092 17.15 10.0023 17.2832C9.37264 18.068 8.68307 18.776 8.13573 19.6253C7.94528 19.9208 7.74196 20.1815 7.49484 20.4286C7.20293 20.7205 7.30264 20.2126 7.32059 19.9531C7.40203 18.7759 7.85234 17.6402 7.99987 16.4681C8.04456 16.1131 8.04821 15.7616 8.05304 15.4049C8.05413 15.3241 8.14054 15.0795 8.10029 15.0091C8.04834 14.9182 7.48938 14.9672 7.39738 14.9559C6.56756 14.8543 5.76079 14.7384 4.92242 14.7138C3.8095 14.681 2.72314 14.6049 1.6205 14.4598C1.52664 14.4474 0.885309 14.357 1.018 14.3298C1.73987 14.1822 2.32577 13.8214 2.97611 13.4911C3.84644 13.049 4.77949 12.7592 5.65486 12.3215C6.34509 11.9764 7.01894 11.5361 7.68091 11.2051" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.5469 4.47564C15.3474 3.47497 16.1479 2.4743 16.9485 1.47363" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.28475 7.13446C4.5567 6.59335 3.78692 6.1136 3.05469 5.59058" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.59375 20.1134C2.51716 19.3267 3.3614 18.4487 4.25267 17.626" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.8008 19.9417C11.8008 21.4616 11.9362 22.9687 11.9723 24.4875" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.8906 12.0234C18.8341 12.0234 19.7776 12.0234 20.7211 12.0234" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ,
    <svg width="21" height="23" viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99219 13.0569C7.73144 15.0183 7.32769 17.4439 9.42653 14.5455C10.4994 13.0639 11.6571 11.6612 12.5023 10.0275C12.8326 9.3889 12.7936 8.79028 13.4922 8.55688" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7839 1.00529C7.36234 1.00529 4.9819 0.748626 3.01294 3.81146C0.946598 7.02577 0.847669 10.3491 1.09718 14.0918C1.53361 20.6382 11.1784 23.3157 16.3962 20.9183C21.2207 18.7017 20.8466 12.1114 19.0405 8.04771C17.5596 4.71575 11.1404 2.94803 7.86978 2.94803" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ,
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.23206 13.2967C3.76102 13.2967 3.1977 13.2245 2.73976 13.3456C1.87879 13.5733 1.38861 14.5982 1.09253 15.3516C0.799611 16.097 1.23724 16.4313 1.91207 16.6319C2.69292 16.864 3.42833 16.9278 4.15867 16.5626C4.96323 16.1603 6.01885 15.1496 5.4471 14.1447C5.26683 13.8279 4.87824 13.2446 4.45223 13.2233C4.18971 13.2101 3.92416 13.2885 3.6857 13.2885C3.5108 13.2885 4.58005 10.2192 4.67241 9.99404C5.09038 8.97524 5.49 7.94031 5.81405 6.88713C5.93044 6.50889 6.14024 6.03329 6.14024 5.6354C6.14024 5.329 6.40983 6.01429 6.44196 6.07167C7.09753 7.24232 7.97585 8.27836 8.69264 9.40691C8.94245 9.80023 9.14203 10.207 9.47548 10.5404C9.63526 10.7002 9.44831 10.6493 9.31239 10.6546C8.78064 10.675 8.70895 11.9433 8.70895 12.3059C8.70895 13.32 9.95515 13.2967 10.7231 13.2967C11.5606 13.2967 11.8218 13.08 11.9545 12.2692C12.0269 11.8269 12.117 11.111 11.7914 10.728C11.4721 10.3523 11.2047 10.2142 10.6905 10.2142C10.303 10.2142 9.7786 10.1372 9.44286 10.361" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.84739 5.37021C7.42733 5.1398 7.66176 2.56373 6.72401 1.55384C6.23767 1.03009 5.6663 1.02363 4.99115 1.0401C3.19565 1.08389 2.88455 2.80192 2.91173 4.26933C2.93762 5.6677 4.46126 6.09996 5.48043 5.59038" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  ,
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.4622 1.89408C13.3211 1.32452 11.1194 0.788916 8.88631 1.08356C5.79127 1.49194 3.35959 3.59204 2.06572 6.37487C1.39786 7.81132 1.07511 9.36733 1.01562 10.9474C0.953185 12.6056 1.04376 14.421 1.92809 15.8819C3.23112 18.0344 5.64911 19.3633 8.01972 20.0008C10.6775 20.7155 14.0127 20.8757 16.6296 19.8682C20.1104 18.5281 21.7332 14.4867 21.4162 10.978C21.2518 9.15751 20.3906 7.47596 19.2752 6.06392C18.2888 4.81513 17.032 3.21782 15.554 2.53638C15.1198 2.33621 14.6306 2.2286 14.1776 2.0776" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.1777 2.07764C14.499 2.68236 14.864 3.25188 15.2278 3.83121C15.5699 4.37584 15.4562 4.38996 15.2788 4.9221" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.4628 4.37135C14.2075 4.40353 10.5013 4.77441 11.6956 4.38664C12.1449 4.24076 12.6259 4.2106 13.0771 4.07059C13.1548 4.04647 13.3103 4.03244 13.2912 3.95334C13.2321 3.70832 12.7807 3.94106 12.5367 4.00432C12.1205 4.11224 10.4704 4.8453 11.8792 4.87091C12.0626 4.87425 14.3016 4.60238 13.9233 4.08588C13.3875 3.35433 11.7496 4.2148 11.4968 4.83523C11.0672 5.88972 14.658 5.0048 14.9836 4.93208C15.0711 4.91253 15.9172 4.70096 16.1051 4.74857C16.7847 4.92079 16.4137 6.36545 16.365 6.66527C16.2546 7.3456 16.0169 8.33805 15.8757 9.02546C15.8456 9.17178 15.5717 10.1109 15.6871 10.3763C15.9632 11.0115 15.3259 9.03373 15.0499 8.39845C15.0438 8.38452 14.1071 6.32641 14.2393 6.26765C14.4434 6.17698 15.6667 7.08106 15.8961 7.18012C16.4825 7.43336 16.7249 7.4238 17.2571 7.18522C17.8489 6.91995 18.4993 7.08413 17.7261 6.28295C16.8102 5.33392 15.9042 5.39303 14.9938 6.35941C14.3828 7.00803 14.2518 7.83323 13.8621 8.59216C13.5664 9.16798 11.1551 7.87659 10.6965 7.73067C10.0562 7.52693 9.36085 7.36733 8.74413 7.72557C8.10771 8.09526 7.23813 8.76375 6.94978 9.45875C5.95879 11.8473 9.53533 11.2911 10.7271 11.5488C11.3642 11.6865 11.3717 13.097 11.4153 13.5572C11.4716 14.152 11.1822 15.8426 12.134 15.9378C12.9845 16.0228 13.8229 14.9973 13.9743 14.2403C14.1084 13.5697 14.1246 13.098 14.5911 12.5326C14.7925 12.2885 17.3571 10.1721 17.1399 10.0042C16.4669 9.48413 15.1788 9.9092 14.4738 10.0909C13.4932 10.3436 12.9524 10.3806 12.0015 10.0093C11.1819 9.68925 10.1033 9.30289 9.22331 9.65246C8.1741 10.0693 10.8065 10.5937 10.9106 10.6618C11.5424 11.0749 11.6167 11.5389 11.9862 12.1605C12.402 12.8601 12.7154 12.9272 12.9038 12.0483C12.9516 11.8252 12.9361 11.2473 12.5877 11.6354C12.2856 11.9721 12.3558 12.4097 12.2513 12.813" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.33203 12.9967C1.58521 13.3162 1.79966 13.6706 2.09157 13.9551C2.20913 14.0696 2.42095 14.2872 2.53507 14.1692C2.73937 13.9579 2.30327 12.5262 2.30058 12.5175C2.22033 12.2609 2.08086 11.6204 1.69906 11.6204" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M1.69922 11.6204C2.30458 12.9715 3.45672 14.7304 3.37633 16.2847C3.37302 16.3486 3.37289 16.418 3.34065 16.4733C3.2508 16.6273 3.37884 16.1183 3.41201 15.9431C3.53993 15.2675 3.6851 14.5952 3.78414 13.9143C3.8701 13.3233 3.89505 13.1674 3.45279 12.8234C2.92501 12.4129 2.39414 12.0853 1.79098 11.8039" stroke="#E32844" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
]

const HomeAboutUs = ({
  aboutUs_flavType,
  aboutUs_flavLeft,
  aboutUs_flavRight,
  aboutUsSectionText,
  aboutUsSectionBulletPoints,
  aboutUsSectionButton
}) => {
  return (
    <StyledHomeAboutUs>
      <FlavText
        type={aboutUs_flavType}
        left={aboutUs_flavLeft}
        right={aboutUs_flavRight} />
      <StyledHomeAboutUs_wapper>
        <StyledHomeAboutUs_container>
          <RichText json={aboutUsSectionText} />
          <BulletPoints>
            {aboutUsSectionBulletPoints.map((item, i) => (
              <BulletPoint key={item}>
                <SvgContainer>
                  {icons[i]}
                </SvgContainer>
                <span>{item}</span>
              </BulletPoint>
            ))}
          </BulletPoints>
          <div>
            <Button
              title={aboutUsSectionButton.title}
              link={aboutUsSectionButton.urlLink}
              color='red'
              type='small'
              reversedColor />
          </div>
        </StyledHomeAboutUs_container>
      </StyledHomeAboutUs_wapper>
    </StyledHomeAboutUs>
  )
}

const StyledHomeAboutUs = styled.div`
  padding-bottom: 50px;

  @media (min-width: ${VW.desktop}) {
    padding-bottom: 100px;
  }
`

const StyledHomeAboutUs_wapper = styled.div`
  ${sectionGrid}
`

const StyledHomeAboutUs_container = styled.div`
  max-width: 600px;
  padding: 0 20px;
  grid-column-start: 5;

  & > p {
    ${typography.body.big}
    margin: 30px 0 20px;
  }

  @media (min-width: ${VW.desktop}) {
    max-width: initial;
    padding: initial;
  
    & > * {
      grid-column-start: 5;
      grid-column-end: 11;
    } 
  }
`

const BulletPoints = styled.div`
  margin: 40px 0;

  @media (min-width: ${VW.desktop}) {
    margin: 15px 0 50px;
    display: grid;
    grid-template-columns: repeat(6,1fr);
  
    & > div {
      &:nth-child(2n+1) {
        grid-column-start: 1;
        grid-column-end: 4;
      }
  
      &:nth-child(2n+0) {
        grid-column-start: 4;
        grid-column-end: 7;
        padding-left: 36px;
      }
    }
  }
`

const BulletPoint = styled.div`
  ${typography.body.small}
  position: relative;
  margin-bottom: 15px;

  & > * {
    display: inline-block;
    vertical-align: middle;
  }

  span {
    padding-left: 8px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const SvgContainer = styled.div`
  position: relative;
  width: 23px;
  height: 23px;
`

export default HomeAboutUs