import React from 'react'
import BlogModules from '../../components/BlogModules'
import styled from 'styled-components'
import { VW, colors, sectionGrid, typography, VARIABLE_CONSTANTS } from '../../styles'
import Button from '../Button'
import RichText from '../RichText'

const BlogModulesSection = ({
  blogSectionTitle: {
    json: title
  },
  blogSectionButton: {
    title: buttonTitle,
    urlLink: buttonUrl
  },
  sections
}) => {
  const blogModules = [sections[sections.length - 1], sections[sections.length - 2]]
  
  return (
    <BlogModules_wrapper>
      <BlogModules_container>
        <BlogModules_content>
          <Intro_wrapper>
            <Title>
              <RichText json={title} />
            </Title>
            <Button
              title={buttonTitle}
              link={buttonUrl}
              type='small'
              color='red' />
          </Intro_wrapper>
          <BlogModules
            sections={blogModules}
            isHomepage />
        </BlogModules_content>
      </BlogModules_container>
    </BlogModules_wrapper>
  )
}

const BlogModules_wrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow: hidden;
  background-color: ${colors.lightGrey};
  border-top: 1px solid black;
  border-bottom: 1px solid black;
`

const BlogModules_container = styled.div`
  ${sectionGrid}
`

const BlogModules_content = styled.div`
  padding: 50px 20px 0;

  @media (min-width: ${VW.desktop}) {
    padding: 100px 20px 0;
  }
`

const Intro_wrapper = styled.div`
  ${typography.headlines.regular}
  margin-bottom: ${VARIABLE_CONSTANTS.mobileBlogSpacing};

  @media (min-width: ${VW.desktop}) {
    margin-top: 0;
  }
`

const Title = styled.div`
  margin-bottom: 10px;

  p { margin: 0 }

  b { color: ${colors.red} }
`

export default BlogModulesSection