import React, { useState } from 'react'
import ProductThumbnail from '../ProductThumbnail'
import styled from 'styled-components'
import {
  VW,
  mobileVW,
  desktopVW,
  thumbnailImage
} from '../../styles'
import { sectionGrid } from '../../styles'
import FlavText from '../FlavText'
import Img from 'gatsby-image'

const FeaturedCollection = ({
  products: pProducts,
  featuredProducts_flavType,
  featuredProducts_flavLeft,
  featuredProducts_flavRight,
  featuredProductsFillerImage
}) => {
  const [loaded, setLoaded] = useState(false)
  
  let products = [...pProducts]

  let showFillerImage = false
  if(featuredProductsFillerImage) {
    products.splice(3, 0, featuredProductsFillerImage);
    showFillerImage = true
  }

  return (
    <StyledFeaturedCollection>
      <FlavText
        type={featuredProducts_flavType}
        left={featuredProducts_flavLeft}
        right={featuredProducts_flavRight} />
      <div className="feature-collection-wrapper">
        <Container>
          {products.map((product, i) => (
              <React.Fragment key={`FeaturedCollection-${product.slug}`}>
                {showFillerImage && i === 3 ? (
                  <FillerImage
                    fluid={product.fluid}
                    scale={loaded}
                    onLoad={() => setLoaded(true)} />
                ) : (
                  <StyledProduct>
                    <ProductThumbnail {...product}/>
                  </StyledProduct>
                )}
              </React.Fragment>
            )
          )}
        </Container>
      </div>
    </StyledFeaturedCollection>
  )
}

const StyledFeaturedCollection = styled.div`
  .feature-collection-wrapper {
    max-width: 600px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 45px;

    @media (min-width: ${VW.desktop}) {
      ${sectionGrid}
      padding-top: 75px;
      padding-bottom: 80px;
    }
  }
`

const Container = styled.div`
  @media (min-width: ${VW.desktop}) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
  }
`

const StyledProduct = styled.div`
  width: 100%;
  margin: 0 0 ${mobileVW(25)} 0;

  img {
    ${thumbnailImage}
  }

  &:nth-child(even) {
    margin-left: ${desktopVW(0)};
  }

  @media (min-width: ${VW.tablet}) {
    margin: 0;
  }
`

const FillerImage = styled(Img)`
  display: none;
  overflow: initial !important;
  
  * { overflow: initial !important; }
  
  img {
    object-fit: contain !important;
    transform: scale(1.1);
  }

  @media (min-width: ${VW.desktop}) {
    display: block;
  }
`

export default FeaturedCollection
